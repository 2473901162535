import { EMetal } from '@core/type';

export const getTranslationKeyFromMetal = (metal: EMetal): string => {
  if (metal === EMetal.GOLD) {
    return 'metalTypes.gold';
  }

  if (metal === EMetal.SILVER) {
    return 'metalTypes.silver';
  }

  if (metal === EMetal.PLATINUM) {
    return 'metalTypes.platinum';
  }

  return 'metalTypes.palladium';
};
