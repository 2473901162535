import { useEventMixpanel } from '@mixpanel';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef } from 'react';

import {
  Box,
  BoxProps,
  ContainerCarouselViews,
  SkeletonTypography,
  Typography,
} from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useCarouselSteps, useQueryApi } from '@core/hook';
import { EColor, EFontWeight, ETypographyVariant } from '@core/type';
import { CollectionsProductGroupsEntity, EQueryKey, ProductEntity } from '@core/type/api';
import { SanityWidget } from '@core/type/sanity';

import { CarouselProducts, sliderHeight } from '../carousel-products';
import {
  CarouselProductGroupComponentEntity,
  CarouselProductGroupProps,
  SlideProps,
} from './interface-carousel-product-group';

export const CarouselProductGroup = ({
  maxProductsCountPerSlide: maxProductsCountPerSlideProps,
  slugs,
  hasTitle,
  ...restProps
}: CarouselProductGroupProps & BoxProps) => {
  const { isPhone } = useContextMediaQuery();
  const { t } = useTranslation(Translate.common.WIDGET);
  const { trackCarouselProductsEvent } = useEventMixpanel();

  const {
    data: collections,
    isLoading,
    isError,
  } = useQueryApi<CollectionsProductGroupsEntity[], { slugs: string[] }>(
    EQueryKey.COMPONENT_COLLECTIONS_PRODUCT,
    { slugs },
  );

  const { currentStep, onClick: setGroupSlide } = useCarouselSteps();
  const products = collections?.[currentStep]?.items || [];

  const { mediaQuery } = useContextMediaQuery();

  useEffect(() => {
    setGroupSlide(0);
  }, [mediaQuery]);

  if (isLoading) {
    return (
      <Box height={sliderHeight(isPhone)} marginBottom={'48px'}>
        <SkeletonTypography lineHeight={'68px'} marginBottom={'8px'} />
        <SkeletonTypography lineHeight={'68px'} />
      </Box>
    );
  }

  if (isError || !collections?.length) {
    return null;
  }

  const trackProductClick = (product: ProductEntity) => {
    trackCarouselProductsEvent(product, collections?.[currentStep]?.name);
  };

  return (
    <Box {...restProps}>
      {hasTitle && (
        <Typography
          marginBottom={'24px'}
          textAlign={isPhone ? 'left' : 'center'}
          variant={ETypographyVariant.H1}
        >
          {t('carouselProductGroup.title')}
        </Typography>
      )}
      {collections.length > 1 && (
        <Box position={'relative'} height={'52px'}>
          <Box
            position={'absolute'}
            display={'flex'}
            justifyContent={!isPhone ? 'center' : 'flex-start'}
            left={'0'}
            right={'0'}
          >
            <ContainerCarouselViews
              justifyContent={!isPhone ? 'center' : 'flex-start'}
              gap={isPhone ? '16px' : '30px'}
              overflowX={'scroll'}
            >
              {collections.map(({ name, slug }, index) => (
                <Slide
                  key={`${name}/${slug}`}
                  index={index}
                  currentStep={currentStep}
                  onClick={() => setGroupSlide(index)}
                >
                  {name}
                </Slide>
              ))}
            </ContainerCarouselViews>
          </Box>
        </Box>
      )}
      <CarouselProducts
        products={products}
        currentStep={currentStep}
        trackProductClick={trackProductClick}
      />
    </Box>
  );
};

const Slide = ({ children, index, currentStep, ...restProps }: SlideProps & BoxProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isPhone } = useContextMediaQuery();
  const isActive = index === currentStep;

  return (
    <Box ref={ref} cursor={'pointer'} {...restProps}>
      <Box position={'relative'} paddingBottom={'28px'}>
        <Typography
          whiteSpace={'nowrap'}
          variant={ETypographyVariant.H5}
          color={isActive ? EColor.ACCENT : EColor.PRIMARY}
          fontWeight={isActive ? EFontWeight.BOLD : EFontWeight.REGULAR}
        >
          {children}
        </Typography>
        <Box
          position={'absolute'}
          backgroundColor={EColor.ACCENT}
          height={'0.5px'}
          left={index === 0 ? '0' : isPhone ? '-16px' : '-30px'}
          right={'0'}
          bottom={'24px'}
          opacity={'0.2'}
        />
        <Box
          position={'absolute'}
          backgroundColor={EColor.ACCENT}
          height={isActive ? '3px' : '0px'}
          borderRadius={4}
          left={'0'}
          right={'0'}
          bottom={'24px'}
          opacity={isActive ? '1' : '0'}
          transition={'0.3s'}
        />
      </Box>
    </Box>
  );
};

export const getCarouselProductGroup = (section: SanityWidget) => (
  <CarouselProductGroup
    slugs={(section as CarouselProductGroupComponentEntity).carouselProducts?.map((slug) =>
      slug.trim(),
    )}
  />
);
